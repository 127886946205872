import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./style.scss";

const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);

    if (!isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  };

  const handleLinkClick = () => {
    setIsOpen(false); // Always close the menu
    document.body.style.overflow = ""; // Reset overflow
  };

  return (
    <div className="burger-wrapper">
      <button
        aria-label="open menu"
        className={`burger-icon ${isOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
      <div className={`menu ${isOpen ? "active" : ""}`}>
        <ul>
          <li className={isOpen ? "slide-in-top" : "slide-out-top"}>
            <NavLink to="/" onClick={handleLinkClick}>
              Home
            </NavLink>
          </li>
          <li className={isOpen ? "slide-in-top" : "slide-out-top"}>
            <NavLink to="/about" onClick={handleLinkClick}>
              About
            </NavLink>
          </li>
          <li className={isOpen ? "slide-in-top" : "slide-out-top"}>
            <NavLink to="/work" onClick={handleLinkClick}>
              Work
            </NavLink>
          </li>
          <li className={isOpen ? "slide-in-top" : "slide-out-top"}>
            <NavLink to="/contact" onClick={handleLinkClick}>
              Contact
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default BurgerMenu;
