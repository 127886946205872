import React, { useState, useEffect } from "react";
import "./style.scss";
import { motion } from "framer-motion";
import "react-lazy-load-image-component/src/effects/blur.css";
import BackButton from "../Js/BackButton/BackButton";
import projectsData from "../Js/Data";
import BurgerMenu from "../BurgerMenu";
import MouseEffects from "../Js/MouseEffects";

const pageVariants = {
  initial: {
    opacity: 0,
    x: "-100vw",
  },
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0,
    x: "100vw",
  },
};

const pageTransition = {
  type: "tween",
  ease: "anticipate",
  duration: 1,
};

const Work = (props) => {
  const [activeProject, setActiveProject] = useState(null);
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    if (projectsData.length > 0) {
      setActiveProject(projectsData[0]); // Set the first project as active
    }
  }, []);

  useEffect(() => {
    if (projectsData.length > 0) {
      setActiveProject(projectsData[0]); // Set the first project as active
    }

    // Apply MouseEffects
    const cleanup = MouseEffects();
    return cleanup; // Cleanup on unmount
  }, []); // Run once when the component mounts

  const handleClick = (project) => {
    if (activeProject && activeProject.id === project.id) {
      // Do nothing if the same project is clicked again
      return;
    } else {
      setActiveProject(project);
      setAnimation(false);
      setTimeout(() => {
        setAnimation(true);
      }, 10);
    }
  };

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      className="work"
    >
      <BurgerMenu />
      <BackButton />
      <div className="container">
        <div className="row-wrapper">
          <div className="details-wrapper">
            {activeProject && (
              <>
                <div
                  className={`project-details ${
                    animation ? "fade-in-right active" : ""
                  }`}
                >
                  <h2>{activeProject.title}</h2>
                  <p>{activeProject.description}</p>
                  <a
                    href={activeProject.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                    }}
                  >
                    Visit here <span style={{ marginLeft: "5px" }}>↗</span>
                  </a>
                </div>
                <div
                  className={`img-wrapper ${
                    animation ? "fade-in-right active" : ""
                  }`}
                >
                  <img
                    src={activeProject.image}
                    alt={activeProject.title}
                    className="img-fluid project-image"
                    loading="lazy" 
                  />
                </div>
              </>
            )}
          </div>

          <div className="work-list-wrapper">
            <h1>Projects I Was Part Of</h1>
            <ul>
              {projectsData.map((project) => (
                <li
                  key={project.id}
                  onClick={() => handleClick(project)}
                  className={
                    activeProject && activeProject.id === project.id
                      ? "active"
                      : ""
                  }
                >
                  <span>{project.title}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Work;
