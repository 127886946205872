import React from "react";
import "./style.scss";
import { motion } from "framer-motion";
import BurgerMenu from "../BurgerMenu";
import BackButton from "../Js/BackButton/BackButton";

const pageVariants = {
  initial: {
    opacity: 0,
    x: "-100vw",
  },
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0,
    x: "100vw",
  },
};

const pageTransition = {
  type: "tween",
  ease: "anticipate",
  duration: 1,
};

const About = (props) => {
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      className="contact"
    >
      <BurgerMenu />
      <BackButton />
      <div className="container wrapping">
        <div className="row">
          <div className="contact-inner">
            <h1>Contact</h1>
            <div className="link-wrapper">
              <div className="mail-wrapper">
                <h2>MAIL</h2>
                <a
                  href="mailto:makristamatina19@gmail.com"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  makristamatina19@gmail.com{" "}
                  <span style={{ marginLeft: "5px" }}>↗</span>
                </a>
              </div>

              <div className="social-wrapper">
                <h2>SOCIAL MEDIA</h2>

                <div className="social-wrapper-inner">
                  <a
                    href="https://www.linkedin.com/in/stamatina-makri"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    LinkedIn <span style={{ marginLeft: "5px" }}>↗</span>
                  </a>

                  <a
                    href="https://github.com/makritina"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Github <span style={{ marginLeft: "5px" }}>↗</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default About;
