import React from "react";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import { ReactComponent as ArrowBackIcon } from "../../../assets/arrow-back.svg";

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <button className="back-button" onClick={() => navigate(-1)}>
      <ArrowBackIcon className="back-icon" />
      Back
    </button>
  );
};

export default BackButton;
