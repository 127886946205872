export default function MouseEffects() {
  const applyCursorEffects = () => {
    console.log("Applying cursor effects...");

    const cursor = document.querySelector(".dot");
    const outerCircle = document.querySelector(".outer-circle");

    if (!cursor || !outerCircle) {
      console.error("Cursor or outer-circle not found!");
      return;
    }

    console.log("Cursor element:", cursor);
    console.log("Outer-circle element:", outerCircle);

    const moveCursor = (e) => {
      const x = e.clientX;
      const y = e.clientY;
      cursor.style.left = `${x}px`;
      cursor.style.top = `${y}px`;
      outerCircle.style.left = `${x}px`;
      outerCircle.style.top = `${y}px`;
    };

    const handleHover = () => {
      console.log("Hovering over an interactive element.");
      outerCircle.classList.add("hovered");
    };

    const handleHoverOut = () => {
      console.log("Stopped hovering over an interactive element.");
      outerCircle.classList.remove("hovered");
    };

    document.addEventListener("mousemove", moveCursor);
    console.log("Mousemove event listener added.");

    const links = document.querySelectorAll("a, button");
    console.log(`Found ${links.length} links and buttons:`, links);
    links.forEach((link) => {
      link.addEventListener("mouseenter", handleHover);
      link.addEventListener("mouseleave", handleHoverOut);
    });

    return () => {
      console.log("Cleaning up cursor effects...");
      document.removeEventListener("mousemove", moveCursor);
      links.forEach((link) => {
        console.log("Removing hover effects for:", link);
        link.removeEventListener("mouseenter", handleHover);
        link.removeEventListener("mouseleave", handleHoverOut);
      });
    };
  };

  applyCursorEffects();
}
