import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import { motion } from "framer-motion";
import BurgerMenu from "../BurgerMenu";

const pageVariants = {
  initial: {
    opacity: 0,
    x: "-100vw",
  },
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0,
    x: "100vw",
  },
};

const pageTransition = {
  type: "tween",
  ease: "anticipate",
  duration: 1,
};

const Home = (props) => {
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      className="home"
    >
      <BurgerMenu />

      <div className="wrap">
        <div className="container ">
          <div className="row">
            <div className="home-inner">
              <h1>
                Hey, I'm{" "}
                <div className="stack" style={{ "--stacks": 3 }}>
                  <span style={{ "--index": 0 }}>Tina</span>
                  <span style={{ "--index": 1 }}>Tina</span>
                  <span style={{ "--index": 2 }}>Tina</span>
                </div>
              </h1>
              <p> A Front End focused Web Developer</p>
              <p className="description">
                turning ideas to real life products is my calling
              </p>
              <div className="link-wrapper">
                <Link to="/work" className="arrow-link">
                  My work
                </Link>
                <Link to="/about" className="arrow-link">
                  More about me
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Home;
